(function () {
    angular.module('informaApp')
        .service('SummaryViewService', SummaryViewService);

    function SummaryViewService(BaseApiService, ConstantsSvc, SummaryViewMapper) {
        return {
            getAll() {
                return BaseApiService.get('summary-views')
                .then((response => SummaryViewMapper.mapAll(response.data.data)));
                /*
                return $http.get(`${ConstantsSvc.API.URL}summary-views`)
                    .then((response => SummaryViewMapper.mapAll(response.data.data)));
                */
            }
        };
    }
})();